import React, { useEffect, useState } from 'react';
import ReviewList from '../components/ReviewList';
import ReviewSideBar from '../components/ReviewSideBar';
import '../styles/Reviews.css';
import { firestore } from '../Firebase'; // Import the firestore instance
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore'; // Import Firestore functions
import { Helmet } from 'react-helmet';

const Reviews = () => {
    const [reviews, setReviews] = useState([]);

    

    useEffect(() => {
        window.scrollTo(0, 0);

        // Subscribe to Firestore updates using the modular approach
        const q = query(collection(firestore, 'reviews'), orderBy('date', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newReviews = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setReviews(newReviews);
    });

        // Clean up listener on unmount
        return () => unsubscribe();
    }, []);

    const totalReviews = reviews.length;
    const averageRating = totalReviews > 0
        ? (reviews.reduce((acc, review) => acc + review.rating_value, 0) / totalReviews).toFixed(1)
        : 0;

        useEffect(() => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                    } else {
                        entry.target.classList.remove('visible');
                    }
                });
            }, { threshold: 0.1 });
    
            // Observing all the elements with the class 'fade-in-section'
            const sections = document.querySelectorAll('.fade-in-section');
            sections.forEach(section => {
                observer.observe(section);
            });
    
            // Cleanup observer on component unmount
            return () => {
                sections.forEach(section => {
                    observer.unobserve(section);
                });
            };
        }, []);

    return (
        <div className="review-wrapper">
            <Helmet>
                <title>Customer Reviews - Hays Total Home Services LLC</title>
                <meta name="description" content="Read genuine customer reviews for Hays Total Home Services LLC to see what our clients say about our home renovation and repair services. Your feedback is important to us!" />
                <meta name="keywords" content="Hays Total Home Services reviews, customer testimonials, home service reviews, Hays feedback" />
                <link rel="canonical" href="https://www.haystotalhs.com/reviews" />
                <meta property="og:title" content="Customer Reviews - Hays Total Home Services LLC" />
                <meta property="og:description" content="Check out our customer reviews and see why Hays Total Home Services LLC is a trusted name in home renovation and repair services." />
                <meta property="og:url" content="https://www.haystotalhs.com/reviews" />
                <meta property="og:type" content="website" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content="Customer Reviews - Hays Total Home Services LLC" />
                <meta property="twitter:description" content="Explore what customers are saying about Hays Total Home Services LLC and our commitment to quality service and customer satisfaction." />
            </Helmet>
            <div className="reviews-grainy-background">
                <div className="fade-in-section"> 
                    <div className="reviews-page">
                        <ReviewSideBar totalReviews={totalReviews} averageRating={averageRating} />
                        <ReviewList reviews={reviews.map(review => ({
                            ...review,
                            author: review.full_name, // Map `full_name` to `author`
                            text: review.review,
                            rating: review.rating_value,
                            date: review.date, // Directly use the `date` string from Firestore
                            // Add other fields as needed
                        }))} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reviews;
