// Review.js
import StarRating from './StarRating';

const Review = ({ author, date, text, rating, verifiedPurchase }) => {
    return (
        <div className="review-container">
            <h4 className="reviewer-name">{author}</h4>
            <div className="review-metadata">
                <span className="review-date">{date}</span>
                {verifiedPurchase && <span className="verified-client">Verified Client</span>}
            </div>
            <div className="review-rating">
            <StarRating rating={rating} interactive={false} />
            </div>
            <p className="review-text">{text}</p>
        </div>
    );
};

export default Review;
