import myImage1 from '../assets/slider-images/001.webp';
import myImage2 from '../assets/slider-images/002.webp';
import myImage3 from '../assets/slider-images/003.webp';
import myImage4 from '../assets/slider-images/004.webp';

export const slidesData = [
    {
        img: myImage1,
    },
    {
        img: myImage2,
    },
    {
        img: myImage3,
    },
    {
        img: myImage4,
    },
];

