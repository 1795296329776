// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // Import the getFirestore function

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCKNb4GlMcR2pINFWZQXhAB1HfBlx0a-Mg",
  authDomain: "hays-total-home-services-858ea.firebaseapp.com",
  projectId: "hays-total-home-services-858ea",
  storageBucket: "hays-total-home-services-858ea.appspot.com",
  messagingSenderId: "666320822879",
  appId: "1:666320822879:web:fe298a000586d84efb31ea",
  measurementId: "G-3FQLFTMKBW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app); // Get a reference to the Firestore service

export { app, firestore }; // Export firestore
