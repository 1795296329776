// StarRating.js
import React, { useState } from 'react';
import { FaStar } from "react-icons/fa";
import '../styles/StarRating.css';

const StarRating = ({ rating, setRating, interactive }) => {
    const [hover, setHover] = useState(null);

    return (
        <div className='stars-container'>
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;
                return (
                    <label key={i}>
                        <input 
                          type='radio' 
                          name='rating' 
                          value={ratingValue} 
                          onClick={() => interactive && setRating(ratingValue)}
                          style={{ display: 'none' }} // Hide the radio buttons
                        />
                        <FaStar
                          size={20}
                          color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                          className='star'
                          onMouseEnter={() => interactive && setHover(ratingValue)}
                          onMouseLeave={() => interactive && setHover(null)}
                        />
                    </label>
                );
            })}
        </div>
    );
};

export default StarRating;



