import React from 'react';
import { Helmet } from 'react-helmet';
import ContactForm from "../components/ContactForm";
import '../styles/Contact.css';
import { useEffect } from 'react';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="contact-wrapper">
            <Helmet>
                <title>Contact Us - Hays Total Home Services LLC</title>
                <meta name="description" content="Get in touch with Hays Total Home Services LLC for all your home renovation needs. Contact us today to schedule a consultation or ask about our services." />
                <meta name="keywords" content="contact home services, home renovation contact, Hays services contact" />
                <link rel="canonical" href="https://www.haystotalhs.com/contact" />
                <meta property="og:title" content="Contact Hays Total Home Services LLC" />
                <meta property="og:description" content="Reach out to Hays Total Home Services LLC for professional home renovation and repair services. Contact us now!" />
                <meta property="og:url" content="https://www.haystotalhs.com/contact" />
                <meta property="og:type" content="website" />
                <meta property="twitter:card" content="summary" />
                <meta property="twitter:title" content="Contact Hays Total Home Services LLC" />
                <meta property="twitter:description" content="Interested in our home services? Contact Hays Total Home Services LLC today to find out more or schedule a consultation." />
            </Helmet>
            <div className="contact-grainy-background">
                <div className="contact-title"><p>Contact Us</p></div>
                <ContactForm />
            </div>
        </div>
    );
}

export default Contact;
