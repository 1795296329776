import React, { useState, useEffect, useRef } from 'react';
import StarRating from './StarRating.js';
import '../styles/ReviewModal.css';
import emailjs from '@emailjs/browser';

const ReviewModal = ({ isOpen, onClose }) => {
    const form = useRef();
    const [rating, setRating] = useState(0);
    const [name, setName] = useState('');
    const [reviewText, setReviewText] = useState('');
    const [showWarning, setShowWarning] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            resetModal();
        }
    }, [isOpen]);

    const resetModal = () => {
        setName('');
        setRating(0);
        setReviewText('');
        setShowWarning(false);
        setIsSubmitted(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || !reviewText || rating === 0) {
            setShowWarning(true);
        } else {
            form.current.user_rating.value = rating; // Set the rating in the form
            form.current.review_date.value = new Date().toLocaleDateString(); // Set the review date

            emailjs.sendForm('service_2u8fif4', 'template_yye63dk', form.current, 'cskIJY7uccEEVr1Er')
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    setIsSubmitted(true);
                }, (error) => {
                    console.log('FAILED...', error);
                });
        }
    };

    if (!isOpen) return null;

    if (isSubmitted) {
        return (
            <div className="review-modal">
                <div className="review-modal-content">
                    <h2>Review Sent!</h2>
                    <p>Thank you for your feedback! Your review is now being processed.</p>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    }

    return (
        <div className="review-modal">
            <div className="review-modal-content">
                <form ref={form} onSubmit={handleSubmit} className='form-container'>
                    <h2>Write a Review</h2>
                    <input type="text" name="user_name" placeholder="Your Full Name" value={name} onChange={e => setName(e.target.value)} />
                    <input type="hidden" name="user_rating" />
                    <input type="hidden" name="review_date" />
                    <StarRating rating={rating} setRating={setRating} interactive={true} />
                    <textarea name="user_review" placeholder="Your Review" value={reviewText} onChange={e => setReviewText(e.target.value)}></textarea>
                    <input type="hidden" name="email_subject" value="REVIEW" />
                    {showWarning && <div className="warning-message">Please complete all fields before submitting</div>}
                    <div className='submit-cancel-buttons'>
                        <button className='submit-button' type="submit">Submit</button>
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ReviewModal;
