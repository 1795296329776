import { slidesData } from '../data/SlidesData';
import React, { useState, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import '../styles/Slider.css'

const Slider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        setCurrentIndex(currentIndex => currentIndex === 0 ? slidesData.length - 1 : currentIndex - 1);
    };

    const nextSlide = () => {
        setCurrentIndex(currentIndex => currentIndex === slidesData.length - 1 ? 0 : currentIndex + 1);
    };  

    useEffect(() => {
        const slideInterval = setInterval(nextSlide, 3000); // Change slides every 3000 milliseconds (3 seconds)
        return () => clearInterval(slideInterval); // Clear interval on component unmount
    }, [currentIndex]);

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    return (
        <div className='slider-wrapper'>
            {slidesData.map((slide, index) => (
                <div
                    key={slide.img}
                    style={{
                        width: '100%', // Set a fixed width for the images
                        height: '110%', // Set a fixed height for the images
                        backgroundImage: `url(${slide.img})`,
                        objectFit: 'fill',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center' // Keep the image centered in the div
                    }}
                    className={`w-full h-full rounded-2xl bg-cover duration-500 absolute transition-opacity ease-in-out ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
                ></div>
            ))}
            {/* Left Arrow */}
            <div className='absolute top-[50%] -translate-y-1/2 left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer z-1' onClick={prevSlide}>
                <BsChevronCompactLeft size={30} />
            </div>
            {/* Right Arrow */}
            <div className='absolute top-[50%] -translate-y-1/2 right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer z-1' onClick={nextSlide}>
                <BsChevronCompactRight size={30} />
            </div>
            {/* Dots */}
            <div className='flex justify-center gap-2 absolute bottom-5 w-full z-10'>
                {slidesData.map((_, slideIndex) => (
                    <div
                        key={slideIndex}
                        className={`h-4 w-4 bg-white rounded-full cursor-pointer ${slideIndex === currentIndex ? 'bg-opacity-100' : 'bg-opacity-50'}`}
                        onClick={() => goToSlide(slideIndex)}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default Slider;
