import './styles/App.css';
import Navbar from './components/Navbar'; 
import Projects from './pages/Projects.jsx';
import Reviews from './pages/Reviews';
import Contact from './pages/Contact';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/Footer.jsx';
import Headroom from 'react-headroom';


function App() {
  return (
    <div className="App">
      <Headroom>
        <Navbar />
      </Headroom>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer>
        <h2>Footer</h2>
      </Footer>
    </div>
  );
}

export default App;