import React, { useState } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import '../styles/Navbar.scss';
import logo from '../assets/business_logo/Red-dark.png';

const Navbar = () => {
    
    const [toggleMenu, setToggleMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleCheckboxChange = () => {
        setToggleMenu(!toggleMenu);
    };

    const handleFooterLinkClick = (path, scrollToId = null) => {
        if (scrollToId) {
            // For the 'Services' link - scrolls to a specific section
            navigate(path, { state: { scrollToId: scrollToId } });
        } else {
            // For other links - just navigate to the path
            navigate(path);
        }
    };

    const handleNavLinkClick = (path, scrollToId) => {
        setToggleMenu(false);
        if (location.pathname !== path && path === '/') {
            // If we're not already on the home page, navigate there and then scroll
            navigate(path, { state: { scrollToId: scrollToId } });
        } else {
            // We're already on the home page, so just scroll to the element
            handleScrollToElement(scrollToId);
        }
    };

    const handleScrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLogoClick = () => {
        setToggleMenu(false);
        handleScrollToTop('/');
    };

    return (
        <nav>
            <Link to="/" className="navbar-logo" onClick={handleLogoClick}>
                <img src={logo} alt="Logo" className='logo-img' />
            </Link>
            <div className="menu">
                <label className="hamburger-menu">
                    <input 
                        type="checkbox" 
                        checked={toggleMenu} 
                        onChange={handleCheckboxChange} 
                    />
                </label>
            </div>
            <ul className={`main-nav ${toggleMenu ? "menuActive" : ""}`}>
                <li>
                    <a onClick={() => handleNavLinkClick('/', 'services-container')} className="nav-link">
                    Services
                    </a>
                </li>
                <li>
                    <NavLink to="/projects" onClick={() => handleNavLinkClick('/projects')} className="nav-link" activeClassName="active-link">
                      Projects
                    </NavLink>
                </li>
                <li><NavLink to="/reviews" onClick={() => handleNavLinkClick('/reviews')} className="nav-link" activeClassName="active-link">Reviews</NavLink></li>
                <li><NavLink to="/contact" onClick={() => handleNavLinkClick('/contact')} className="nav-link" activeClassName="active-link">Contact</NavLink></li>
            </ul>
        </nav>
    );
}

export default Navbar;