// projectsData.js

// Importing images for each project

// project 1 (office)
import proj1_img1 from '../assets/project-images/project-1/1.webp';
import proj1_img2 from '../assets/project-images/project-1/2.webp';
import proj1_img3 from '../assets/project-images/project-1/3.webp';
import proj1_img4 from '../assets/project-images/project-1/4.webp';
import proj1_img5 from '../assets/project-images/project-1/5.webp';

// project 2 (porch)
import proj2_img1 from '../assets/project-images/project-2/1.webp';
import proj2_img2 from '../assets/project-images/project-2/2.webp';
import proj2_img3 from '../assets/project-images/project-2/3.webp';
import proj2_img4 from '../assets/project-images/project-2/4.webp';
import proj2_img5 from '../assets/project-images/project-2/5.webp';

// project 3 (medinas bathroom)
import proj3_img1 from '../assets/project-images/project-3/1.webp';
import proj3_img2 from '../assets/project-images/project-3/2.webp';
import proj3_img3 from '../assets/project-images/project-3/3.webp';
import proj3_img4 from '../assets/project-images/project-3/4.webp';

// ... Import images for other projects

const projectsData = [
  {
    title: 'Office Remodel',
    images: [proj1_img5, proj1_img1, proj1_img2, proj1_img3, proj1_img4]
  }, 
  {
    title: 'Porch Build',
    images: [proj2_img4, proj2_img1, proj2_img2,proj2_img3, proj2_img5]
  }, 
  {
    title: 'Bathroom Remodel',
    images: [proj3_img4, proj3_img1, proj3_img2,proj3_img3]
  }, 
  // ... Other project objects
];

export default projectsData;
