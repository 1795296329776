// ReviewSideBar.js
import React, { useState } from 'react';
import StarRating from './StarRating';
import ReviewModal from './ReviewModal';

const ReviewSideBar = ({ totalReviews, averageRating }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    return (
        <aside className="sidebar">
            <div className="review-stats">
                <div className="average-rating">
                    <span>Average Rating</span>
                    <StarRating rating={averageRating} interactive={false} />

                </div>
                <div className="total-reviews">{totalReviews} Client Ratings</div>
                {/* Optional: Rating breakdown */}
            </div>
            <button className="write-review-btn" onClick={openModal}>Write a review</button>
            <ReviewModal isOpen={isModalOpen} onClose={closeModal} />
        </aside>
    );
};

export default ReviewSideBar;
