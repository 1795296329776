import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState, useRef, useEffect } from 'react'; // Import useRef
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../styles/ProjectImageSlider.css';

import { EffectCoverflow, Pagination, Navigation, EffectFlip, EffectCube, EffectFade } from 'swiper/modules';

function ProjectImageSlider({ images, title }) {

  const [clickedImageIndex, setClickedImageIndex] = useState(null);


  // Event handler to enlarge the image
const handleImageClick = (e, index) => {
  // Check if we're on a mobile device by window width
  const isMobile = window.innerWidth <= 500;

  // Check if the clicked slide is the active one and we're not on mobile
  const isActiveSlide = e.currentTarget.classList.contains('swiper-slide-active');
  if (isActiveSlide && !isMobile) {
    setClickedImageIndex(clickedImageIndex === index ? null : index);
  }
};

// Additional state to track preloaded images
const [preloadedImages, setPreloadedImages] = useState({});

// Function to preload image
const preloadImage = (src) => {
  const image = new Image();
  image.src = src;
};

useEffect(() => {
  // Preload first few images initially
  const initialPreloads = images.slice(0, 3); // Adjust this number based on how many you want to preload initially
  initialPreloads.forEach((img) => preloadImage(img));
  setPreloadedImages(initialPreloads.reduce((acc, img) => ({ ...acc, [img]: true }), {}));
}, [images]);


const handleSlideChange = (swiper) => {
  // Preload next image
  const nextSlideIndex = swiper.activeIndex + 1;
  const nextImage = images[nextSlideIndex];
  if (nextImage && !preloadedImages[nextImage]) {
    preloadImage(nextImage);
    setPreloadedImages((prev) => ({ ...prev, [nextImage]: true }));
  }
  
  if (clickedImageIndex !== swiper.activeIndex) {
    setClickedImageIndex(null);
  }
};

const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        setIsVisible(entries[0].isIntersecting);
      },
      { threshold: 0.3 } // Adjust threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div className={`my-unique-container ${isVisible ? 'fade-in' : 'fade-out'}`} ref={containerRef}>
      <h1 className="heading">{title}</h1>
      <div className="main-swiper-container">
      <Swiper
        onSlideChange={handleSlideChange}
        preloadImages={true} // Disable Swiper's default preloading
        watchSlidesProgress={true} // Enable advanced preloading
        effect={'coverflow'}
        grabCursor={false}
        centeredSlides={true}
        loop={true}
        slidesPerView={window.innerWidth <= 500 ? 1 : 'auto'}
        coverflowEffect={{
          rotate: 5,
          stretch: 0,
          depth: 150,
          modifier: 3,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
          {images.map((image, index) => (
            <SwiperSlide key={index} onClick={(e) => handleImageClick(e, index)}>
              <LazyLoadImage
                src={image}
                alt={`slide_image_${index}`}
                effect="blur"
                className={clickedImageIndex === index ? 'active' : ''}
                threshold={300} // Start loading 300px before the image comes into view
              />
            </SwiperSlide>
          ))}
          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}

export default ProjectImageSlider;
