import React, { useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import '../styles/Footer.css';
import fb from '../assets/icons/fb.png';
import insta from '../assets/icons/insta.png';
import twitter from '../assets/icons/twitter.png';
import linkedin from '../assets/icons/linkedin.png';

const Footer = () => {
    const navigate = useNavigate();

    const handleFooterLinkClick = (path, scrollToId = null) => {
        if (scrollToId) {
            // For links with a specific scroll target
            navigate(path, { state: { scrollToId: scrollToId } });
        } else {
            // For other links - just navigate and scroll to top
            navigate(path);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const location = useLocation();

    useEffect(() => {
        const scrollToId = location.state?.scrollToId;
        if (scrollToId) {
            // Scroll to specific element
            setTimeout(() => {
                const element = document.getElementById(scrollToId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100); // Adjust delay as needed
        } else {
            // Scroll to top if no specific element is targeted
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <footer>
            <div className='sb__footer section__padding'>
                <div className="sb__footer-links">
                    <div className="sb__footer-links-div">
                        <h4>Resources</h4>
                        <Link to="/" state={{ scrollToId: 'services-container' }}>
                            <p>Services</p>
                        </Link>
                        <Link to="/projects" onClick={() => handleFooterLinkClick('/projects')}>
                            <p>Projects</p>
                        </Link>
                        <Link to="/reviews" onClick={() => handleFooterLinkClick('/reviews')}>
                            <p>Reviews</p>
                        </Link>
                        <Link to="/contact" onClick={() => handleFooterLinkClick('/contact')}>
                            <p>Contact</p>
                        </Link>
                        {/* ... */}
                    </div>
                    <div className="sb__footer-links-div">
                        <h4>Company</h4>
                        <Link to="/" state={{ scrollToId: 'mission-title' }}>
                            <p>Our Mission</p>
                        </Link>
                    </div>
                    <div className="sb__footer-links-div">
                    <Link to="/contact" onClick={() => handleFooterLinkClick('/contact')}>
                            <h4 className='contact-us-link'>Contact Us</h4>
                        </Link>
                        <div className="email">
                            <p>haystotalhs@gmail.com</p>
                        </div>
                        {/* <div className="socialmedia">
                            <p><img src={fb} alt="" /></p>
                            <p><img src={twitter} alt="" /></p>
                            <p><img src={linkedin} alt="" /></p>
                            <p><img src={insta} alt="" /></p>
                        </div> */}
                    </div>
                </div>
                <hr></hr>
                <div className="sb__footer-below">
                    <div className="sb__footer-copyright">
                        <p>
                            @{new Date().getFullYear()} Hays Total Home Services LLC. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
