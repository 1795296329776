import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../styles/TestimonialsSlider.css';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"; 


const TestimonialsSlider = () => {

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} custom-slick-next`} style={{ ...style, display: "block", zIndex: 0 }} onClick={onClick}>
        <IoIosArrowForward size={30} color="#333" /> {/* Dark icon color */}
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={`${className} custom-slick-prev`} style={{ ...style, display: "block", zIndex: 0}} onClick={onClick}>
        <IoIosArrowBack size={30} color="#333"/>
      </div>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Adjusts when the screen width is 768px or less
        settings: {
          slidesToShow: 1, // Show only one slide
          slidesToScroll: 1
        }
      }
    ]
  };

      const data = [
        {
          name: `Adler Family`,
          review: `Outstanding and diligent work on fixing our refrigerator and kitchen wall. Highly recommend the thorough job this company executed on. We will continue to utilize them.`
        },
        {
          name: `C. Duran`,
          review: `Ruben's dedication and exceptional work in our home have left us truly grateful. His hard work and attention to detail transformed our space into something remarkable. We wholeheartedly recommend him for his outstanding contributions and commendable work ethic. We are fortunate to have had Rubens professionalism and talent.`
        },
        {
          name: `J. Parsons`,

          review: `Ruben exhibits outstanding traits including attention to detail, generosity, creativity, friendliness, a service ethic, adaptability, trustworthiness, respectfulness, and good manners. I've experienced Ruben to be a rare individual with exceptional abilities for home construction projects. I always enjoy working with him.`
        },
        
      ];

      return ( 
        <div className='w-3/4 m-auto'>
            <div className="mt-20">
            <Slider {...settings}>
                {data.map((d) => (
                <div key={d.name} className="slide bg-white h-[400px] text-black rounded-xl"> {/* Adjusted height */}
                    <div className="flex flex-col items-center justify-center gap-6 p-4">
                    <p className="text-xl font-semibold">{d.name}</p>
                    <p className="text-center">{d.review}</p>
                    </div>
                </div>
                ))}
            </Slider>
            </div>
        </div>
      );
}
 
export default TestimonialsSlider;