// Projects.jsx
import React from 'react';
import { Helmet } from 'react-helmet';
import ProjectImageSlider from "../components/ProjectImageSlider";
import projectsData from '../data/ProjectsData'; // Adjust the path as needed
import '../styles/Projects.css';
import { useEffect } from 'react';

const Projects = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="projects-container">
            <Helmet>
                <title>Our Projects - Hays Total Home Services LLC</title>
                <meta name="description" content="Explore our completed projects showcasing our expertise in home renovation and repair services. Hays Total Home Services LLC has a proven track record of transforming homes in Kyle and Buda, Texas." />
                <meta name="keywords" content="home projects, renovation examples, home service projects, project gallery, Hays Total Home Services" />
                <link rel="canonical" href="https://www.haystotalhs.com/projects" />
                <meta property="og:title" content="Our Projects - Hays Total Home Services LLC" />
                <meta property="og:description" content="Take a look at our project gallery to see the quality and scope of home renovation and repair work we perform at Hays Total Home Services LLC." />
                <meta property="og:url" content="https://www.haystotalhs.com/projects" />
                <meta property="og:type" content="website" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content="Our Projects - Hays Total Home Services LLC" />
                <meta property="twitter:description" content="Discover the wide range of home renovation and repair projects completed by Hays Total Home Services LLC, your trusted home service provider in Kyle and Buda, Texas." />
            </Helmet>
            {projectsData.map((project, index) => (
                <div key={index}>
                    <ProjectImageSlider images={project.images} title={project.title} />
                </div>
            ))}
        </div>
    );
}

export default Projects;
