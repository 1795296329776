import Slider from '../components/Slider';
import TestimonialsSlider from '../components/TestimonialsSlider';
import '../styles/Home.css'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';


const Home = () => {
    const location = useLocation();

    useEffect(() => {
        const scrollToId = location.state?.scrollToId;
        if (scrollToId) {
            const element = document.getElementById(scrollToId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [location]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                } else {
                    entry.target.classList.remove('visible');
                }
            });
        }, { threshold: 0.1 });

        // Observing all the elements with the class 'fade-in-section'
        const sections = document.querySelectorAll('.fade-in-section');
        sections.forEach(section => {
            observer.observe(section);
        });

        // Cleanup observer on component unmount
        return () => {
            sections.forEach(section => {
                observer.unobserve(section);
            });
        };
    }, []);

    return (
        
        <div className="home-wrapper">
            <Helmet>
                <title>Hays Total Home Services LLC - Expert Home Renovation & Maintenance</title>
                <meta name="description" content="Hays Total Home Services LLC provides comprehensive home maintenance and renovation services in Kyle and Buda, Texas. Our expert team is committed to delivering top-notch service tailored to your needs." />
                <meta name="keywords" content="home renovation, home maintenance, Kyle Texas, Buda Texas, home repair services, home remodeling" />
                <link rel="canonical" href="https://www.haystotalhs.com/" />
                <meta property="og:title" content="Hays Total Home Services LLC - Home Renovation & Maintenance" />
                <meta property="og:description" content="Looking for professional home maintenance or renovation services? Discover Hays Total Home Services LLC’s exceptional service coverage in Kyle and Buda, Texas." />
                <meta property="og:url" content="https://www.haystotalhs.com/" />
                <meta property="og:type" content="website" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content="Hays Total Home Services LLC - Expert Home Renovation & Maintenance" />
                <meta property="twitter:description" content="Professional home services including maintenance and renovation in Kyle and Buda, Texas. Click to learn more about Hays Total Home Services LLC." />
            </Helmet>
            <div className="grainy-background">
            <div className="fade-in-section"> 
                <div className="title-container">
                    <h1 className="title-top">Hays Total Home Services</h1>
                </div>
            </div>
            <div className="fade-in-section"> 
                <div className="slider-container"><Slider/></div>
            </div>
            <div className="fade-in-section"> 
                <div className="company-information-container">
                    <div className="mission-title" id='mission-title'>
                        <h2>Our Mission</h2>
                    </div>
                    <div className="mission-bio">
                        <p>Hays Total Home Services offers an array of services for your home needs. We are more than happy to service your home. We understand that some home tasks can be out of some homeowner’s comfort zones, and for some homeowners they just are too busy to do it themselves. Hays Total Homes Services is here to help you. We currently offer services in Kyle and Buda, Texas. We are planning on servicing Hays County, Texas in the near future.</p>
                    </div>
                </div>
            </div>
            <div className="fade-in-section"> 
                <div className="services-container" id="services-container">
                    <div className="services-title">
                        <h2>Services</h2>
                    </div>
                
                    <div className="category">
                        <h3 className="category-title">Home Maintenance and Repair</h3>
                        <p className="category-description">We are here to help you with your total home maintenance and repair needs. Our maintenance services will help you keep emergency repairs costs down. We strive to provide regular maintenance to those items that are the most expensive to repair or replace. Our maintenance service can routinely replace A/C air filter in the attic, to performing an annual flush on your water heater.</p>
                        <ul className="services-list">
                        <li className="service-item">
                            <strong>Maintenance Services:</strong> We focus on regular upkeep to minimize the cost of emergency repairs. From replacing A/C air filters in the attic to conducting annual flushes on your water heater, we ensure your home remains in pristine condition.
                        </li>
                        <li className="service-item">
                            <strong>Repair Services:</strong> Whether it's a minor issue like a crack in your drywall or a more pressing repair such as a faucet or shower that won't turn off, we've got you covered. Our repair services are aimed at addressing both the small and urgent needs of your home, keeping it in excellent condition.
                        </li>
                        </ul>
                        <h3 className="category-title">Remodeling</h3>
                        <ul className="services-list-special">
                            <li className="service-item-special">- Personalized home transformation</li>
                            <li className="service-item-special">- Flooring installation and upgrades</li>
                            <li className="service-item-special">- Bathroom renovations for luxury and functionality</li>
                            <li className="service-item-special">- Kitchen remodeling to modernize your space</li>
                            <li className="service-item-special">- Expert guidance and execution from concept to completion</li>
                        </ul>
                    </div>
                </div>
                </div>
                <div className="fade-in-section"> 
                    <div className="testimonials-container">
                        <div className="testimonials-title">
                            <h2>Testimonials</h2>
                        </div>
                        <TestimonialsSlider/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
