import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import '../styles/ContactForm.css';

const ContactForm = () => {
    const form = useRef();
    const [submitted, setSubmitted] = useState(false);
    const [allFieldsFilled, setAllFieldsFilled] = useState(true);
    const [startDate, setStartDate] = useState(null); // State to handle the date

    const sendEmail = (e) => {
        e.preventDefault();

        if (!startDate) {
            setAllFieldsFilled(false);
            return;
        }

        // Set the value of the completion date in your form
        form.current.completion_date.value = startDate.toISOString().split('T')[0];
    
        if (!form.current.user_name.value || !form.current.user_email.value || 
            !form.current.zip_code.value || !form.current.job_description.value || 
            !form.current.completion_date.value) {
            setAllFieldsFilled(false);
            return;
        }
    
        // Check if the completion date is within the next week
        const completionDate = new Date(form.current.completion_date.value);
        const currentDate = new Date();
        const oneWeekLater = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000));
    
        // Set the urgency field based on the completion date
        form.current.urgency.value = completionDate < oneWeekLater ? "*URGENT*" : "";
    
        emailjs.sendForm('service_ujyrgps', 'template_drf7bwe', form.current, 'cskIJY7uccEEVr1Er')
          .then((result) => {
              console.log(result.text);
              setSubmitted(true);
          }, (error) => {
              console.log(error.text);
          });
    };

    if (submitted) {
        return (
            <div className="confirmation-wrapper">
                <div className="confirmation-message">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                    <p className="confirmation-text">Your message has been sent successfully!</p>
                    <p className="confirmation-subtext">We'll get back to you soon.</p>
                </div>
            </div>
        );
    }

    return (
        <form id="myForm" ref={form} onSubmit={sendEmail} noValidate>
            {!allFieldsFilled && <p className="error-message">Please fill out all fields before submitting.</p>}
            <input type="hidden" name="urgency" value="" />
            <input type="text" name="user_name" placeholder="Your Full Name" />
            <input type="email" name="user_email" placeholder="Your Email" />
            <input type="phone" name="user_phone" placeholder="Your Phone Number" />
            <input type="text" name="zip_code" placeholder="Zip Code" />
            <textarea name="job_description" placeholder="Job Description"></textarea>
            <label htmlFor="completion_date">Desired Start Date</label>
            <DatePicker 
                selected={startDate} 
                onChange={(date) => setStartDate(date)} 
                placeholderText="MM/DD/YYYY"
                name="completion_date"
                id="completion_date"
            />
            <input type="submit" value="Send" />
        </form>
    );
};

export default ContactForm;
